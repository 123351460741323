// layout
export const SHOW_MENU = "SHOW_MENU"

// sermons
export const SHOW_SERMONS_FILTER_MENU = "SHOW_SERMONS_FILTER_MENU"
export const SET_SERMON_BOOK_FILTER = "SET_SERMON_BOOK_FILTER"
export const SET_SERMON_SPEAKER_FILTER = "SET_SERMON_SPEAKER_FILTER"
export const SET_SERMON_SEARCH_FILTER = "SET_SERMON_SEARCH_FILTER"

// sundayschool
export const SHOW_SUNDAYSCHOOL_FILTER_MENU = "SHOW_SUNDAYSCHOOL_FILTER_MENU"
export const SET_SUNDAYSCHOOL_BOOK_FILTER = "SET_SUNDAYSCHOOL_BOOK_FILTER"
export const SET_SUNDAYSCHOOL_SPEAKER_FILTER = "SET_SUNDAYSCHOOL_SPEAKER_FILTER"
export const SET_SUNDAYSCHOOL_SERIES_FILTER = "SET_SUNDAYSCHOOL_SERIES_FILTER"
export const SET_SUNDAYSCHOOL_SEARCH_FILTER = "SET_SUNDAYSCHOOL_SEARCH_FILTER"
